import React from 'react';

import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    paper: {
        maxWidth: 1200,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    tableButton: {
        fontWeight: 'bold',
        color: localStorage.getItem('theme') === 'dark' ?
            theme.palette.getContrastText("#bb86fc") :
            theme.palette.getContrastText(colors.purple[500]),

        backgroundColor: localStorage.getItem('theme') === 'dark' ?
            "#bb86fc" :
            colors.purple[500],
        '&:hover': localStorage.getItem('theme') === 'dark' ?
            {
                backgroundColor: "#9370db"
            } :
            {
                backgroundColor: colors.purple[700]
            },
        minWidth: 80
    }
})

class TableComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            cellContent: [
                {
                    id: '1',
                    name: "TABLE 1",
                    label: "For Survey of Agricultural Land Under Part II and Part IV of the Land Ordinance (CAP. 68)",
                },
                {
                    id: '2',
                    name: "TABLE 2",
                    label: "For Survey of Land Held Under Part II, Part III and Part IV of the Land Ordinance (Cap. 68) for Other Than Agricultural Purposes",
                },
                {
                    id: '3',
                    name: "TABLE 3",
                    label: "For Survey for Subdivision of Buildings for Issuance of Subsidiary Titles",
                },
                {
                    id: '4',
                    name: "TABLE 4",
                    label: "For Resurvey for the Refixation of Replacement of Boundary Marks",
                },
                {
                    id: '5',
                    name: "TABLE 5",
                    label: "For Land Acquisition Surveys",
                },
                {
                    id: '6',
                    name: "TABLE 6",
                    label: "For Survey of Land for Reserves & Special Purpose",
                },
                {
                    id: '7',
                    name: "TABLE 7",
                    label: "For Survey of Control Traverses",
                },
                {
                    id: '8',
                    name: "TABLE 8",
                    label: "For Preparation of Compilation Plans",
                },
                {
                    id: '9',
                    name: "TABLE 9",
                    label: "For Professional Consultation Fees and Other Services",
                },
                {
                    id: '10',
                    name: "TABLE 10",
                    label: "Time Rates",
                },
                {
                    id: '11',
                    name: "TABLE 11",
                    label: "Control Point Positioning by Satellite",
                },
                {
                    id: '12',
                    name: "TABLE 12",
                    label: "Survey of Land for Mining",
                },
                {
                    id: '13',
                    name: "TABLE 13",
                    label: "For Survey of Land for Temporary Occupation License (T.O.L.)",
                },
                {
                    id: '14',
                    name: "TABLE 14",
                    label: "Disbursement"
                },
                // {
                //     id: '15',
                //     name: "ADJUSTMENT TABLE",
                //     label: "For Survey Fees Adjustment"
                // }
            ]
        }
    }
    //sorting function for table
    sortHandler = (clickedColumn) => () => {
        const { column, cellContent, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                contentCell: _.sortBy(cellContent, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            contentCell: cellContent.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    render() {
        const { classes } = this.props
        const { column, direction, cellContent } = this.state

        const cellHeader = [
            {
                id: "table_name",
                label: "Table Name",
            },
            {
                id: "table_desc",
                label: "Table Description"
            },
        ]

        return (
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table>

                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell
                                    key={cellHeader.id}
                                    padding="default"
                                    align="center"
                                    sortDirection={column === cellHeader.id ? direction : null}
                                    >
                                        <TableSortLabel
                                            active={column === cellHeader.id}
                                            direction={direction}
                                            onClick={this.sortHandler(cellHeader.id)}
                                        >
                                            {cellHeader.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                
                                <TableCell
                                padding="default"
                                align="center"
                                >
                                    Action
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {cellContent.map(cellContent => (
                                <TableRow key={cellContent.id}>
                                    <TableCell align="center" style={{ width: '30%'}}>
                                        {cellContent.name}
                                    </TableCell>
                                    <TableCell align="center" style={{ width: '50%'}}>
                                        {cellContent.label}
                                    </TableCell>
                                    <TableCell align="center" style={{ width: '20%'}}>
                                        <Button className={classes.tableButton}
                                        onClick={() => this.props.action(cellContent.id)}
                                        >
                                            Select
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);