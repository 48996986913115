import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';
import DateInputComponent from '../../Util/DateInputComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.esp_no}>

                        <Grid item xs={12} sm={4}>
                            ESP No:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.esp_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.esp_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Remarks:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.remark}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            LJS E3 Date.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ljs3_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            LJS E7 Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ljs7_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            Updated By:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            Updated At:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Edit"
                    />
                </Box>
            </div>
        )
    }
}

class Grid1ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            input_esp_date: null,
            input_ljs3_date: null,
            input_ljs7_date: null
        }
    }

    componentDidMount() {
        let input_esp_date = this.props.data[0]['esp_date']
        let input_ljs3_date = this.props.data[0]['ljs3_date']
        let input_ljs7_date = this.props.data[0]['ljs7_date']

        if (input_esp_date === 'No information') {
            input_esp_date = null
        }
        else {
            input_esp_date = this.dateConverter(input_esp_date)
        }

        if (input_ljs3_date === 'No information') {
            input_ljs3_date = null
        }
        else {
            input_ljs3_date = this.dateConverter(input_ljs3_date)
        }

        if (input_ljs7_date === 'No information') {
            input_ljs7_date = null
        }
        else {
            input_ljs7_date = this.dateConverter(input_ljs7_date)
        }

        this.setState({
            input_esp_date: input_esp_date,
            input_ljs3_date: input_ljs3_date,
            input_ljs7_date: input_ljs7_date
        })
    }

    //Date converter function added on 01/11/2021 to convert string value to date object
    dateConverter = (value) => {
        var dateParts = value.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 

        return dateObject
    }

    onChangeHandler_esp_date = date => {
        this.setState({ input_esp_date: date })
    }

    onChangeHandler_ljs3_date = date => {
        this.setState({ input_ljs3_date: date })
    }

    onChangeHandler_ljs7_date = date => {
        this.setState({ input_ljs7_date: date })
    }

    submitHandler = () => {
        if (!this.state.input_esp_date) {
            var formatted_esp_date = ""
        }
        else {
            formatted_esp_date = (this.state.input_esp_date).getDate() + '/' + ((this.state.input_esp_date).getMonth() + 1) + '/' + (this.state.input_esp_date).getFullYear()
        }

        if (!this.state.input_ljs3_date) {
            var formatted_ljs3_date = ""
        }
        else {
            formatted_ljs3_date = (this.state.input_ljs3_date).getDate() + '/' + ((this.state.input_ljs3_date).getMonth() + 1) + '/' + (this.state.input_ljs3_date).getFullYear()
        }

        if (!this.state.input_ljs7_date) {
            var formatted_ljs7_date = ""
        }
        else {
            formatted_ljs7_date = (this.state.input_ljs7_date).getDate() + '/' + ((this.state.input_ljs7_date).getMonth() + 1) + '/' + (this.state.input_ljs7_date).getFullYear()
        }

        this.props.action(
            this.input_esp_no.value,
            formatted_esp_date,
            formatted_ljs3_date,
            formatted_ljs7_date,
            this.input_remark.value,
        )
    }

    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.esp_no}>

                        <Grid item xs={12} sm={4}>
                            ESP No:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                label="ESP Number"
                                //ESP Number has 11 digits
                                inputProps={{ maxLength: 11 }}
                                inputRef={node => (this.input_esp_no = node)}
                                defaultValue={x.esp_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <DateInputComponent
                                label="ESP Date"
                                name="input_esp_date"
                                value={this.state.input_esp_date}
                                onChange={this.onChangeHandler_esp_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Remarks:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                label="ESP Remarks"
                                inputProps={{ maxLength: 250 }}
                                inputRef={node => (this.input_remark = node)}
                                defaultValue={x.remark}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            LJS E3 Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <DateInputComponent
                                label="LJS E3 Date"
                                name="input_ljs3_date"
                                value={this.state.input_ljs3_date}
                                onChange={this.onChangeHandler_ljs3_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            LJS E7 Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <DateInputComponent
                                label="LJS E7 Date"
                                name="input_ljs7_date"
                                value={this.state.input_ljs7_date}
                                onChange={this.onChangeHandler_ljs7_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            Updated By:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            Updated At:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonLoadComponent
                            color="primary"
                            className={this.props.classNameButton}
                            name="Updating..."
                        />

                        <ButtonLoadComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            name="..."
                        />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonIdleComponent
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                            name="Update"
                        />
                        <ButtonIdleComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                            name="Cancel"
                        />
                    </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class ESPComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        esp_no, esp_date, ljs3_date, ljs7_date, remark
    ) => {

        this.props.action(
            esp_no, esp_date, ljs3_date, ljs7_date, remark
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        ESP/LJS3/LJS7 Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>

                        {this.state.viewState === true ?
                            <React.Fragment>
                                <Grid1Component
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>

                            :

                            <React.Fragment>
                                <Grid1ComponentEdit
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    loading={this.props.loading}
                                    action={this.submitHandler}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>
                        }

                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ESPComponent);